.App {
  background-color: #fff;
  text-align: center;
}

.pe {
  font-weight: bold;
  margin: 5px;
  color: #fff;
  font-size: 2em;
}
.ped {
  font-weight: bold;
  margin: 5px;
  color: #fff;
  font-size: 1em;
}

.wrapper {
  padding: 20px;
}

.App-header {
  background-color: #006DB8;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}
.searchh {
  background-color: #006DB8;
  height: 10vh;
  display: flex;
  align-items: center;
  margin-left: 200px;
  justify-content: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.months {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.monthlist {
  list-style: none;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  display: flex;
  padding: 10px;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-wrap: wrap;
}

.monthlistkeptar {
  list-style: none;
  padding-top: 20vh;
  height: 80vh;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow: auto;
  display: flex;
  padding: 10px;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-wrap: wrap;
}


li.listitem {
  width: 15vw;
  height: 20vh;
  background-color: #fff;
  border: 2px solid #006DB8;
  color: #fff;
  cursor: pointer;
  overflow: auto;
  border-radius: 10px;
  margin: 8px;
  transition: all .3s;
}

li.listitem:hover {
  transform: scale(1.05);
}

li.headerlist {
  border-radius: 10px;
  background-color: #fff;
  color: #006DB8;
  width: 14vw;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 2vh;
  padding: 10px;
  margin: 1rem;
  transition: all .3s;
}

li.headerlist:hover {
  transform: scale(1.1);
}

.itemtitle {
  background-color: #006DB8;
  color: #fff;
  border-radius: 7px 7px 0px 0px;
  font-weight: bold;
  font-size: 2vw;
  padding-bottom: 5px;
  padding-top: 5px;
}

.monthimages {
  width: 180px;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  list-style: none;
  align-items: center;
  justify-content: center;
}

.imagesli {
  list-style: none;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  overflow: auto;
  margin: 5px;
  background-color: #fff;
}

.imageslim {
  list-style: none;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  overflow: hidden;
  margin: 2px;
  background-color: #fff;
}

.imageslimkeptar {
  list-style: none;
  width: 15vw;
  cursor: pointer;
  height: 20vh;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 4px;
  background-color: #fff;
  transition: all .3s;
}

.imageslimkeptar:hover {
  transform: scale(1.05);
}

.imagesul {
  height: 120px;
  border-radius: 0px 0px 8px 8px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  align-items: center;
  justify-content: center;
}


.calendar-container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-container {
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.input {
  height: 60vh;
  width: 50vw;
  border-radius: 20px;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputfield {
  font-size: 14px;
  height: 40px;
  width: 200px;
  font-weight: bold;
  border-radius: 10px;
  margin: 5px;
  padding-left: 10px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
}
.inputfieldhosszu {
  font-size: 14px;
  height: 40px;
  width: 420px;
  font-weight: bold;
  border-radius: 10px;
  margin: 5px;
  padding-left: 10px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
}

.inputfield:focus {
  border-bottom: 1px solid #6941c6;
  -webkit-transition: 0.1s;
  transition: 0.5s;
}

.App-link {
  color: #61dafb;
}

.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.calendar-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  gap: 10px;
}

.calendar-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #006DB8;
  border-radius: 12px;
  color: #fff;
  padding: 10px;
  height: 15vh;
  width: 10vw;
  box-sizing: border-box;
}

.calendar-day:hover {
  background-color: #005793;
  cursor: pointer;
}

.calendar-day-date {
  font-size: 2vh;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.calendar-day-user {
  margin-top: 5px;
  font-size: 16px;
  list-style-type: none;
  margin: 0;
  width: 8vw;
  height: 20vh;
  background-color: #fff;
  border-radius: 12px;
  overflow: auto;
  padding: 5px;
  font-weight: bold;
}


